import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Typography,
  Grid,
  Avatar,
  Button,
  Card,
  TextField,
  IconButton,
  Divider,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import UploadIcon from '@mui/icons-material/Upload';
import DeleteIcon from '@mui/icons-material/Delete';
import Dashboard from './Dashboard';
import { updateUserProfile } from '../redux/authSlice';
import { fetchCategories } from "../redux/categoriesSlice";
import ToastNotification, { showSuccessToast } from "../components/ToastNotification";
import { ImageBaseUrl } from '../Network/constant';
import Autocomplete from '@mui/material/Autocomplete';

const SettingsScreen = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const categories = useSelector((state) => state.categories.categories);
  const [formData, setFormData] = useState({
    name: user?.name || user?.data?.name || '',
    email: user?.email || user?.data?.email || '',
    profileImage: user?.image || user?.data?.image || null,
  });
  const [selectedCategories, setSelectedCategories] = useState([]); // Multi-select state

  console.log("formData", formData);

  // Handle change for input fields
  const handleChange = (e) => {
    if (e.target.name === 'profileImage') {
      setFormData({
        ...formData,
        profileImage: e.target.files[0], // Handle image file
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    const updatedData = new FormData();
    updatedData.append('name', formData.name);
    updatedData.append('email', formData.email);
    if (formData.profileImage) {
      updatedData.append('image', formData.profileImage);
    }
    updatedData.append('categories', selectedCategories.map(category => category.id).join(','));

    console.log("updatedData", updatedData);

    // return;

    dispatch(updateUserProfile({ formData: updatedData }))
      .unwrap()
      .then(() => {
        showSuccessToast('Profile updated successfully');
      })
      .catch(() => {
        showSuccessToast('Profile updated successfully');
      });
  };

  useEffect(() => {
    dispatch(fetchCategories()); // Fetch categories when component mounts
  }, [dispatch]);

  useEffect(() => {
    if (categories.length > 0 && user?.categories) {
      const matchedCategories = user.categories.map(userCategory => {
        return categories.find(category => category.id === userCategory.categoryId);
      }).filter(Boolean); // Remove undefined values
      setSelectedCategories(matchedCategories);
    }
  }, [categories, user?.categories]);

  return (
    <Dashboard>
      <Container maxWidth="md" sx={{ mt: 4 }}>
        <Card sx={{ p: 3, borderRadius: '16px', boxShadow: '0 6px 20px rgba(0, 0, 0, 0.1)' }}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Avatar
                  src={`${ImageBaseUrl}/${formData.profileImage || user?.image}`}
                  alt={user?.name}
                  sx={{ width: 120, height: 120, border: '2px solid #f0f0f0' }}
                />
              </Grid>
              <Grid item xs={12} sm={6} sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                  {user?.name || 'Ali'}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Box>
                  <Button variant="outlined" startIcon={<UploadIcon />} component="label" sx={{ mr: 1, mb: 1 }}>
                    Upload
                    <input
                      type="file"
                      name="profileImage"
                      accept="image/*"
                      hidden
                      onChange={handleChange}
                    />
                  </Button>
                  <IconButton color="error">
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Grid>
            </Grid>

            <Divider sx={{ my: 2 }} />

            {/* Editable Name Field */}
            <Grid container spacing={2} sx={{ alignItems: 'center' }}>
              <Grid item xs={9}>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                  Name
                </Typography>
                <TextField
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
            </Grid>

            <Divider sx={{ my: 2 }} />

            {/* Editable Email Field */}
            <Grid container spacing={2} sx={{ alignItems: 'center' }}>
              <Grid item xs={9}>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                  Email
                </Typography>
                <TextField
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                  type="email"
                  disabled
                />
              </Grid>
            </Grid>

            <Divider sx={{ my: 2 }} />

            {/* Multi-Select Dropdown for Categories */}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
                  Select Categories
                </Typography>
                <Autocomplete
                  multiple
                  options={categories}
                  getOptionLabel={(option) => option.name}
                  value={selectedCategories}
                  onChange={(event, newValue) => {
                    setSelectedCategories(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Choose categories"
                    />
                  )}
                  sx={{
                    backgroundColor: "#f5f5f5",
                    borderRadius: "8px",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#ddd",
                      },
                      "&:hover fieldset": {
                        borderColor: "#bbb",
                      },
                    },
                  }}
                />
              </Grid>
            </Grid>

            <Divider sx={{ my: 2 }} />

            <Button
              type="submit"
              variant="contained"
              sx={{
                mt: 3,
                backgroundColor: "#1976d2",
                color: "#fff",
                fontWeight: "bold",
                py: 1.5,
                borderRadius: "8px",
                fontSize: "1.2rem",
                "&:hover": {
                  backgroundColor: "#1565c0",
                },
              }}
            >
              Update Profile
            </Button>
          </form>
        </Card>
      </Container>
      <ToastNotification />
    </Dashboard>
  );
};

export default SettingsScreen;