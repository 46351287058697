import React from "react";
import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  Button,
  CardContent,
  CardActions,
  Avatar,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import head from "../assets/icons/head.jpeg";
import architects from "../assets/icons/architects.jpeg";
import construction from "../assets/icons/construction.jpeg";
import interiordesign from "../assets/icons/interiordesign.jpeg";


const HomeScreen = () => {
  const navigate = useNavigate();
  const services = [
    {
      id: 4,
      title: "I want to hire an architect",
      icon: architects,
      isImage: true,
      buttonText: "Architecture",
    },
    {
      id: 5,
      title: "I want to hire a construction company",
      icon: construction,
      isImage: true,
      buttonText: "Construction",
    },
    {
      id: 6,
      title: "I want to hire an interior designer",
      icon: interiordesign,
      isImage: true,
      buttonText: "Interior design",
    },
  ];

  const testimonials = [
    {
      name: "Yousaf Malik",
      avatar: "https://via.placeholder.com/150",
      title: "Homeowner",
      quote:
        "ApniChhat helped me find the perfect contractor for my home renovation",
    },
    {
      name: "Zeerak Rana",
      avatar: "https://via.placeholder.com/150",
      title: "Architect",
      quote:
        "As an architect, I love the network ApniChhat provides. I've connected with several clients through their platform.",
    },
    {
      name: "Saqib Awan",
      avatar: "https://via.placeholder.com/150",
      title: "Interior Designer",
      quote:
        "Finding new projects has never been easier thanks to ApniChhat. Highly recommended for professionals in the industry.",
    },
  ];

  const faqs = [
    {
      question: "What services does ApniChhat provide?",
      answer:
        "ApniChhat connects you with a wide range of professionals including architects, contractors, and interior designers to help you with your building and renovation needs.",
    },
    {
      question: "How do I find the right professional for my project?",
      answer:
        "You can use our search function to find professionals in your area and filter by the specific service you need. Once you find a professional, you can view their profile and contact them directly.",
    },
    {
      question: "What is the process of hiring a contractor through ApniChhat?",
      answer:
        "The process is simple: search for contractors in your area, view their profiles, and reach out to them directly to discuss your project. We recommend meeting with a few contractors to find the best fit for your needs.",
    },
    {
      question: "Is there a cost to use ApniChhat?",
      answer:
        "ApniChhat is free to use for homeowners and project managers. We connect you with professionals at no charge.",
    },
    {
      question: "How can I ensure the quality of the professionals listed?",
      answer:
        "All professionals on our platform are vetted based on their credentials, experience, and customer reviews. We recommend reviewing their portfolio and client feedback to ensure they meet your standards.",
    },
  ];

  return (
    <>
      {/* Hero Section */}

      {/* <Container> */}
      {/* <Grid container alignItems="center" spacing={4}> */}
      {/* <Grid item xs={12} md={6}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems={{ xs: "center", md: "flex-start" }}
              >
                <Typography
                  variant="h3"
                  align="center"
                  gutterBottom
                  sx={{ color: "#fff", fontWeight: "bold" }}
                >
                  Welcome to Apni Chhat.
                </Typography>
                <Typography variant="body1" sx={{ color: "#f0f0f0" }}>
                  We are dedicated to connecting clients with top-tier
                  construction firms, and innovative architects and interior
                  designers, all under one roof. With Apni Chhat, you can easily
                  browse profiles, view portfolios, and select the perfect team
                  to execute your vision; we’ve got the right professionals to
                  help you every step of the way. From planning to execution, we
                  ensure that your ideas meet expertise to turn your vision into
                  reality. From concept to completion, Apni Chhat is your
                  partner in building spaces that inspire.
                </Typography>
              </Box>
            </Grid> */}
      {/* 
            <Grid
              item
              xs={12}
              md={6}
              sx={{ display: "flex", justifyContent: "center" }}
            > */}
      <Box sx={{ position: "relative", width: "100%", height: "70vh" }}>
        {/* Background Image */}
        <Box
          component="img"
          src={head}
          alt="Illustration"
          className="hero-image"
          sx={{ width: "100%", height: "70vh", }}
        />

        {/* Overlay Text on Right Side */}
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "1%", // Adjust this value as needed
            transform: "translateY(-50%)",
            color: "white",
            fontSize: "3rem",
            fontWeight: "bold",
            textAlign: "right",
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            padding: "10px 20px",
            borderRadius: "10px",
          }}
        >
          One Step Closer to Your Dream Home with ApniChhat!
        </Box>
      </Box>


      {/* Overlay Text */}

      {/* </Grid> */}
      {/* </Grid> */}
      {/* </Container> */}

      {/* Search Section */}
      {/* <SearchSections /> */}

      {/* Service Options Section */}
      <Box sx={{ py: 8, backgroundColor: "#fff" }}>
        <Container maxWidth="lg">
          <Typography variant="h4" gutterBottom sx={{ color: "#333" }}>
            Welcome to Apni Chhat.
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            sx={{ color: "#555", mb: 4 }}
          >
            We are dedicated to connecting clients with top-tier
            construction firms, and innovative architects and interior
            designers, all under one roof. With Apni Chhat, you can easily
            browse profiles, view portfolios, and select the perfect team
            to execute your vision; we’ve got the right professionals to
            help you every step of the way. From planning to execution, we
            ensure that your ideas meet expertise to turn your vision into
            reality. From concept to completion, Apni Chhat is your
            partner in building spaces that inspire.
          </Typography>

          <Grid container spacing={4}>
            {services.map((service, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card
                  sx={{
                    backgroundColor: "#01AEFE",
                    color: "white",
                    textAlign: "center",
                    height: "100%",
                    transition: "transform 0.3s",
                    "&:hover": {
                      transform: "scale(1.05)",
                    },
                  }}
                  onClick={() => navigate("/contractors", { state: { service: service.id } })}
                >
                  <CardContent sx={{
                    paddingBottom: "16px", // Reduced bottom padding
                  }}>
                    {service.isImage ? (
                      <Box
                        component="img"
                        src={service.icon}
                        alt={service.title}
                        sx={{
                          width: "100px",
                          height: "100px",
                          display: "block",
                          margin: "0 auto",
                          borderRadius: "50%",
                        }}
                      />
                    ) : (
                      <Box
                        sx={{
                          fontSize: "3rem", // Large emoji size
                          textAlign: "center",
                          display: "block",
                        }}
                      >
                        {service.icon} {/* Render emoji */}
                      </Box>
                    )}
                    <Typography
                      variant="h6"
                      sx={{
                        mb: 2,
                        minHeight: "48px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {service.title}
                    </Typography>
                    <CardActions sx={{
                      justifyContent: "center",
                      padding: "8px 16px", // Reduced padding  
                      marginTop: "auto", // Push the button to the bottom
                    }}>
                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: "#1976d2",
                          color: "#fff",
                          "&:hover": {
                            backgroundColor: "#1565c0",
                          },
                          marginBottom: "10px",
                        }}
                      >
                        {service.buttonText}
                      </Button>
                    </CardActions>
                  </CardContent>

                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
      <Box sx={{ py: 10, backgroundColor: "#f0f4f8", textAlign: "center" }}>
        <Container maxWidth="md">
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              color: "#333",
              fontWeight: "bold",
              mb: 4,
              fontSize: "2.5rem",
            }}
          >
            Join ApniChhat as a Company
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            sx={{
              color: "#555",
              mb: 6,
              fontSize: "1.2rem",
              lineHeight: "1.6",
            }}
          >
            Create a company account and get access to powerful tools to manage
            projects, connect with professionals, and grow your business. Join
            our network of trusted companies today!
          </Typography>

          <Grid container justifyContent="center">
            <Grid item xs={12} md={8}>
              <Card
                sx={{
                  background: "linear-gradient(135deg, #01AEFE, #42a5f5)",
                  color: "#fff",
                  boxShadow: "0 8px 20px rgba(0,0,0,0.2)",
                  textAlign: "center",
                  borderRadius: "16px",
                  transition: "transform 0.3s, box-shadow 0.3s",
                  "&:hover": {
                    transform: "scale(1.05)",
                    boxShadow: "0 12px 30px rgba(0,0,0,0.3)",
                  },
                  p: 4,
                }}
              >
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      mb: 3,
                    }}
                  >
                    <Box
                      sx={{
                        fontSize: "3rem",
                        borderRadius: "50%",
                        backgroundColor: "#fff",
                        color: "#1976d2",
                        padding: "20px",
                        boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
                      }}
                    >
                      🏢
                    </Box>
                  </Box>
                  <Typography
                    variant="h5"
                    gutterBottom
                    sx={{
                      fontWeight: "bold",
                      fontSize: "1.8rem",
                      mb: 2,
                    }}
                  >
                    Sign Up as a Company
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#f0f0f0",
                      fontSize: "1.1rem",
                      mb: 3,
                    }}
                  >
                    Register your company to gain access to project management
                    tools and a network of professionals.
                  </Typography>
                </CardContent>
                <CardActions sx={{ justifyContent: "center" }}>
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{
                      backgroundColor: "#fff",
                      color: "#1976d2",
                      fontWeight: "bold",
                      py: 1.5,
                      borderRadius: "8px",
                      fontSize: "1.2rem",
                      "&:hover": {
                        backgroundColor: "#f0f0f0",
                      },
                    }}
                    onClick={() => navigate("/signup")}
                  >
                    Sign Up Now
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Testimonials Section */}
      {/* Calculator Option Section */}
      <Box sx={{ py: 8, backgroundColor: "#fff", textAlign: "center" }}>
        <Container maxWidth="lg">
          <Typography variant="h4" gutterBottom sx={{ color: "#333" }}>
            Construction Cost Calculator
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            sx={{ color: "#555", mb: 4 }}
          >
            Estimate the cost of building your dream house. Click below to
            access the construction cost calculator.
          </Typography>

          <Grid container spacing={4} justifyContent="center">
            <Grid item xs={12} md={6}>
              <Card
                sx={{
                  backgroundColor: "#1976d2",
                  color: "#fff",
                  textAlign: "center",
                  transition: "transform 0.3s",
                  "&:hover": {
                    transform: "scale(1.05)",
                  },
                  p: 4,
                }}
              >
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    Calculate Now
                  </Typography>
                </CardContent>
                <CardActions sx={{ justifyContent: "center" }}>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#fff",
                      color: "#1976d2",
                      "&:hover": {
                        backgroundColor: "#f0f0f0",
                      },
                    }}
                    onClick={() => navigate("/calculator")}
                  >
                    Go to Calculator
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Sign Up Section */}
      <Box sx={{ py: 8, backgroundColor: "#f5f5f5", textAlign: "center" }}>
        <Container maxWidth="lg">
          <Typography variant="h4" gutterBottom sx={{ color: "#333" }}>
            What Our Clients Say
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            sx={{ color: "#555", mb: 4 }}
          >
            Hear from our satisfied clients about their experiences with
            ApniChhat.
          </Typography>

          <Grid container spacing={4}>
            {testimonials.map((testimonial, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card
                  sx={{
                    backgroundColor: "#fff",
                    color: "#333",
                    textAlign: "center",
                    height: "100%",
                    boxShadow: "0 4px 15px rgba(0,0,0,0.1)",
                    transition: "transform 0.3s",
                    "&:hover": {
                      transform: "scale(1.05)",
                    },
                  }}
                >
                  <CardContent>
                    <Avatar
                      src={testimonial.avatar}
                      alt={testimonial.name}
                      sx={{
                        width: 80,
                        height: 80,
                        margin: "0 auto 16px",
                        boxShadow: "0 4px 10px rgba(0,0,0,0.2)",
                      }}
                    />
                    <Typography variant="h6" gutterBottom>
                      {testimonial.name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {testimonial.title}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        mt: 2,
                        color: "#555",
                        fontStyle: "italic",
                      }}
                    >
                      "{testimonial.quote}"
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>


      <Box sx={{ py: 8, backgroundColor: "#f5f5f5", textAlign: "center" }}>
        <Container maxWidth="md">
          <Typography variant="h4" gutterBottom sx={{ color: "#333" }}>
            Frequently Asked Questions
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            sx={{ color: "#555", mb: 4 }}
          >
            Here are some common questions about our platform and services. If
            you have more questions, feel free to contact us.
          </Typography>

          {faqs.map((faq, index) => (
            <Accordion key={index} sx={{ mb: 2 }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}
              >
                <Typography variant="h6">{faq.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{faq.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Container>
      </Box>
      <Box sx={{ py: 10, backgroundColor: "#fff", textAlign: "center" }}>
        <Container maxWidth="md">
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              color: "#333",
              fontWeight: "bold",
              mb: 4,
              fontSize: "2.5rem",
            }}
          >
            Our App is Coming Soon!
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            sx={{ color: "#555", mb: 6, fontSize: "1.2rem", lineHeight: "1.6" }}
          >
            We are working hard to bring you an intuitive mobile experience. Stay
            tuned for updates and be the first to access exclusive features that
            will make hiring professionals easier than ever!
          </Typography>
          {/* <Button
            variant="contained"
            size="large"
            sx={{ backgroundColor: "#1976d2", color: "#fff" }}
          >
            Notify Me
          </Button> */}
        </Container>
      </Box>

      {/* <Box
        sx={{
          py: 8,
          backgroundImage: "linear-gradient(to right, #01AEFE, #01AEFE)",
          color: "#fff",
        }}
      >
        <Container maxWidth="md">
          <Typography
            variant="h4"
            gutterBottom
            sx={{ textAlign: "center", color: "#fff" }}
          >
            Get Started with ApniChhat
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            sx={{ textAlign: "center", mb: 4, color: "#f0f0f0" }}
          >
            Follow these simple steps to begin your journey with us.
          </Typography>

          <Grid container spacing={4}>
            {steps.map((step, index) => (
              <Grid item xs={12} sm={4} key={index}>
                <Card
                  sx={{
                    backgroundColor: "#fff",
                    color: "#1976d2",
                    textAlign: "center",
                    height: "100%",
                    transition: "transform 0.3s",
                    "&:hover": {
                      transform: "scale(1.05)",
                    },
                  }}
                >
                  <CardContent>
                    <Box
                      sx={{
                        fontSize: "40px",
                        mb: 2,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {step.icon}
                    </Box>
                    <Typography variant="h6" gutterBottom>
                      {step.step}
                    </Typography>
                    <Typography
                      variant="h5"
                      gutterBottom
                      sx={{ fontWeight: "bold" }}
                    >
                      {step.title}
                    </Typography>
                    <Typography variant="body2">{step.description}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>

          <Box sx={{ textAlign: "center", mt: 6 }}>
            <Button
              variant="contained"
              size="large"
              sx={{
                backgroundColor: "#f0f0f0",
                color: "#1976d2",
                "&:hover": {
                  backgroundColor: "#e0e0e0",
                },
              }}
            >
              Get Started Now
            </Button>
          </Box>
        </Container>
      </Box> */}
    </>
  );
};

export default HomeScreen;