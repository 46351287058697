import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Grid,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Checkbox,
  ListItemText,
  IconButton,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import Dashboard from "./Dashboard";
import { fetchCities } from "../redux/citiesSlice";
import { createPortfolio, updatePortfolio } from "../redux/portfoliosSlice";
import ToastNotification, {
  showErrorToast,
  showSuccessToast,
} from "../components/ToastNotification";
import { fetchCategories } from "../redux/categoriesSlice";
import { fetchBuilding } from "../redux/buildingSlice";


const AddPortfolio = () => {
  const location = useLocation(); // Access passed state
  const navigate = useNavigate();
  const port = location.state?.portfolio;

  const dispatch = useDispatch();
  const { cities } = useSelector((state) => state.cities);
  // const { building } = useSelector((state) => state.building);
  // const categories = useSelector((state) => state.categories.categories);

  // console.log(categories);

  // console.log('cities', cities);

  console.log('port', port);


  const categories =
    [
      {
        id: 1,
        name: "Hotel & Resorts",
      },
      {
        id: 2,
        name: "HealthCare",
      },
      {
        id: 3,
        name: "Restaurants",
      },
      {
        id: 4,
        name: "Office Spaces",
      },
      {
        id: 5,
        name: "Residential",
      },
      {
        id: 6,
        name: "Commercial",
      },
      {
        id: 7,
        name: "Cultural Center",
      },
      {
        id: 8,
        name: "Fitness Center",
      },
      {
        id: 9,
        name: "Landscape",
      },
    ]


  useEffect(() => {
    dispatch(fetchCategories());
    dispatch(fetchCities());
    dispatch(fetchBuilding());
  }, [dispatch]);
  const [portfolio, setPortfolio] = useState({
    title: port?.title || "",
    content: port?.content || "",
    facebookLink: port?.facebookLink || "",
    twitterLink: port?.twitterLink || "",
    linkedinLink: port?.linkedinLink || "",
    youtubeLink: port?.youtubeLink || "",
    instagramLink: port?.instagramLink || "",
    tiktokLink: port?.tiktokLink || "",
    price: port?.price || "",
    phone: port?.phone || "",
    email: port?.email || "",
    website: port?.website || "",
    magazine: port?.magzine || "",
    city: port?.portfolioCities?.[0]?.cityId || "", // Store single city ID
    buildingTypes: port?.portfolioBuildingTypes?.map((type) => type.buildingTypeId) || [], // Keep multiple building types
    images: port?.portfolioImages || [],
  });
  
  


  // Handle input changes for text fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPortfolio({ ...portfolio, [name]: value });
  };

  // Handle file input change for multiple image upload
  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const filePreviews = selectedFiles.map((file) => ({
      file,
      preview: URL.createObjectURL(file),
    }));
    setPortfolio({
      ...portfolio,
      images: [...portfolio.images, ...filePreviews],
    });
  };


  const handleBuildingTypesChange = (e) => {
    setPortfolio({ ...portfolio, buildingTypes: e.target.value });
  };


  const removeImage = (index) => {
    const updatedImages = portfolio.images.filter((_, i) => i !== index);
    setPortfolio({ ...portfolio, images: updatedImages });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Create a new FormData object to handle file upload and other data
    const formData = new FormData();
    formData.append("title", portfolio.title);
    formData.append("content", portfolio.content);
    formData.append("facebookLink", portfolio.facebookLink);
    formData.append("twitterLink", portfolio.twitterLink);
    formData.append("linkedinLink", portfolio.linkedinLink);
    formData.append("youtubeLink", portfolio.youtubeLink);
    formData.append("instagramLink", portfolio.instagramLink);
    formData.append("tiktokLink", portfolio.tiktokLink);
    formData.append("price", portfolio.price);
    formData.append("phone", portfolio.phone);
    formData.append("email", portfolio.email);
    formData.append("website", portfolio.website);
    formData.append("magzine", portfolio.magazine);
    formData.append("publication", portfolio.publication);
    formData.append("cities[]", portfolio.city);
    portfolio.buildingTypes.forEach((buildingId) => {
      formData.append("buildingTypes", buildingId);
    });

    portfolio.images.forEach((imageObj) => {
      formData.append("images", imageObj.file);
    });
    // return;

    if (port) {
      dispatch(updatePortfolio({ id: port.id, updatedData: formData }))
        .unwrap()
        .then(() => {
          showSuccessToast("Portfolio updated successfully");
          setPortfolio({
            title: "",
            content: "",
            facebookLink: "",
            twitterLink: "",
            linkedinLink: "",
            youtubeLink: "",
            instagramLink: "",
            tiktokLink: "",
            price: "",
            phone: "",
            email: "",
            website: "",
            magazine: "",
            publication: "",
            cities: [],
            images: [],
            buildingTypes: [],
          });
          navigate("/overview");
        })
        .catch((error) => {
          showErrorToast(error.message);
        });
    } else {
      dispatch(createPortfolio(formData))
        .unwrap()
        .then(() => {
          showSuccessToast("Portfolio added successfully");
          setPortfolio({
            title: "",
            content: "",
            facebookLink: "",
            twitterLink: "",
            linkedinLink: "",
            youtubeLink: "",
            instagramLink: "",
            tiktokLink: "",
            price: "",
            phone: "",
            email: "",
            website: "",
            magazine: "",
            publication: "",
            cities: [],
            images: [],
          });
          navigate("/overview");
        })
        .catch((error) => {
          showErrorToast(error.message);
        });
    }
  };

  return (
    <Dashboard>
      {" "}
      {/* Wrapping inside Dashboard */}
      <Container maxWidth="md">
        <Box sx={{ py: 6 }}>
          <Card>
            <CardContent>
              <Typography variant="h5" sx={{ mb: 4 }}>
                Add New Portfolio
              </Typography>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  {/* Title */}
                  <Grid item xs={12}>
                    <TextField
                      label="Title"
                      variant="outlined"
                      fullWidth
                      name="title"
                      value={portfolio.title}
                      onChange={handleInputChange}
                      required
                    />
                  </Grid>

                  {/* Content */}
                  <Grid item xs={12}>
                    <TextField
                      label="Content"
                      variant="outlined"
                      fullWidth
                      name="content"
                      value={portfolio.content}
                      onChange={handleInputChange}
                      multiline
                      rows={4}
                      required
                    />
                  </Grid>

                  {/* Social Media Links */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Facebook Link"
                      variant="outlined"
                      fullWidth
                      name="facebookLink"
                      value={portfolio.facebookLink}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Twitter Link"
                      variant="outlined"
                      fullWidth
                      name="twitterLink"
                      value={portfolio.twitterLink}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="LinkedIn Link"
                      variant="outlined"
                      fullWidth
                      name="linkedinLink"
                      value={portfolio.linkedinLink}
                      onChange={handleInputChange}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Instagram Link"
                      variant="outlined"
                      fullWidth
                      name="instagramLink"
                      value={portfolio.instagramLink}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="TikTok Link"
                      variant="outlined"
                      fullWidth
                      name="tiktokLink"
                      value={portfolio.tiktokLink}
                      onChange={handleInputChange}
                    />
                  </Grid>

                  {/* Other Info */}
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel>Price</InputLabel>
                      <Select
                        value={portfolio.price}
                        name="price"
                        onChange={handleInputChange}
                      >
                        <MenuItem value="100-120">100-120</MenuItem>
                        <MenuItem value="120-250">120-250</MenuItem>
                        <MenuItem value="250-above">250-above</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Phone"
                      variant="outlined"
                      fullWidth
                      name="phone"
                      value={portfolio.phone}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Email"
                      variant="outlined"
                      fullWidth
                      name="email"
                      value={portfolio.email}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Website"
                      variant="outlined"
                      fullWidth
                      name="website"
                      value={portfolio.website}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Magazine"
                      variant="outlined"
                      fullWidth
                      name="magazine"
                      value={portfolio.magazine}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Address"
                      variant="outlined"
                      fullWidth
                      name="Address"
                      value={portfolio.youtubeLink}
                      onChange={(e) => setPortfolio({ ...portfolio, youtubeLink: e.target.value })}
                    />
                  </Grid>

                  {/* Multi-Select for Cities */}
                  <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                  <InputLabel shrink={true} sx={{ backgroundColor: "white", px: 1 }}>
                    City
                  </InputLabel>
                  <Select
                    name="city"
                    value={portfolio.city}
                    onChange={(e) => setPortfolio({ ...portfolio, city: e.target.value })}
                    displayEmpty
                    sx={{ textAlign: "left" }} // Ensure proper alignment
                  >
                    <MenuItem value="" disabled>
                      Select a city
                    </MenuItem>
                    {Array.isArray(cities) &&
                      cities.map((city) => (
                        <MenuItem key={city.id} value={city.id}>
                          {city.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>

                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel>
                        Specialization
                      </InputLabel>
                      <Select
                        multiple
                        name="specialization"
                        value={portfolio.buildingTypes}
                        onChange={handleBuildingTypesChange}
                        renderValue={(selected) =>
                          selected.map((id) => categories.find((type) => type.id === id)?.name).join(", ")
                        }
                      >
                        {categories.map((type) => (
                          <MenuItem key={type.id} value={type.id}>
                            <Checkbox checked={portfolio.buildingTypes.includes(type.id)} />
                            <ListItemText primary={type.name} />
                          </MenuItem>
                        ))}
                      </Select>

                    </FormControl>
                  </Grid>

                  {/* Image Upload */}
                  <Grid item xs={12}>
                    <input
                      type="file"
                      name="images"
                      accept="image/*"
                      multiple
                      onChange={handleFileChange}
                    />
                  </Grid>

                  {/* Display selected images */}
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      {portfolio.images.map((imageObj, index) => (
                        <Grid item xs={4} key={index}>
                          <Box sx={{ position: "relative" }}>
                            <img
                              src={imageObj.preview}
                              alt="Selected"
                              style={{ width: "100%", borderRadius: "8px" }}
                            />
                            <IconButton
                              sx={{
                                position: "absolute",
                                top: 8,
                                right: 8,
                                backgroundColor: "white",
                              }}
                              onClick={() => removeImage(index)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>

                  {/* Submit Button */}
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                    >
                      {port ? "Update Portfolio" : "Add Portfolio"}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
        </Box>
        <ToastNotification />
      </Container>
    </Dashboard>
  );
};

export default AddPortfolio;
