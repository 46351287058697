import React, { useState } from "react";
import {
  Box,
  Container,
  Typography,
  Card,
  TextField,
  Button,
  CardContent,
  CardActions,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../redux/authSlice";
import ToastNotification, { showSuccessToast, showErrorToast } from "../components/ToastNotification";
import { useNavigate } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const LoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.auth);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(loginUser(formData))
      .unwrap()
      .then(() => {
        showSuccessToast("Login successful");
        setTimeout(() => {
          navigate("/overview");
        }, 2000);
      })
      .catch(() => {
        showErrorToast("Login failed. Please check your credentials");
      });
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundImage: "linear-gradient(135deg, #01AEFE, #42a5f5)",
        padding: "50px 0",
      }}
    >
      <Container maxWidth="sm">
        <Card
          sx={{
            background: "#fff",
            borderRadius: "16px",
            boxShadow: "0 8px 20px rgba(0,0,0,0.2)",
            p: 4,
            textAlign: "center",
            transition: "transform 0.3s",
            "&:hover": {
              transform: "scale(1.05)",
              boxShadow: "0 12px 30px rgba(0,0,0,0.3)",
            },
          }}
        >
          <CardContent>
            <Typography
              variant="h4"
              sx={{ fontWeight: "bold", color: "#1976d2", mb: 3 }}
            >
              Login to Your Account
            </Typography>

            <Box component="form" onSubmit={handleSubmit}>
              <TextField
                fullWidth
                label="Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                variant="outlined"
                type="email"
                sx={{ mb: 3 }}
                required
              />
              <TextField
                fullWidth
                label="Password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                variant="outlined"
                type={showPassword ? "text" : "password"}
                sx={{ mb: 4 }}
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={toggleShowPassword} edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <Button
                type="submit"
                variant="contained"
                fullWidth
                disabled={loading}
                sx={{
                  backgroundColor: loading ? "#ccc" : "#1976d2",
                  color: "#fff",
                  fontWeight: "bold",
                  py: 1.5,
                  borderRadius: "8px",
                  fontSize: "1.2rem",
                  "&:hover": {
                    backgroundColor: loading ? "#ccc" : "#1565c0",
                  },
                }}
              >
                {loading ? "Logging in..." : "Login"}
              </Button>

              <Button
                onClick={() => navigate("/forget-password")}
                sx={{
                  textTransform: "none",
                  color: "#1976d2",
                  fontWeight: "bold",
                  mt: 2,
                  "&:hover": {
                    textDecoration: "underline",
                    backgroundColor: "transparent",
                  },
                }}
              >
                Forgot Password?
              </Button>
            </Box>
          </CardContent>

          <CardActions sx={{ justifyContent: "center", mt: 2 }}>
            <Typography variant="body2">
              Don't have an account?{" "}
              <Button
                sx={{
                  textTransform: "none",
                  color: "#1976d2",
                  fontWeight: "bold",
                  "&:hover": {
                    backgroundColor: "transparent",
                    textDecoration: "underline",
                  },
                }}
                onClick={() => navigate("/signup")}
              >
                Sign up here
              </Button>
            </Typography>
          </CardActions>
        </Card>
      </Container>
      <ToastNotification />
    </Box>
  );
};

export default LoginPage;