import React, { useEffect } from 'react';
import { Box, Container, Typography, Grid, Avatar, Paper, Card, CardMedia, CardContent, CardActionArea, Chip } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPortfolios } from "../redux/portfoliosSlice";
import { ImageBaseUrl } from "../Network/constant";

const ProfileDetailScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { portfolio } = location?.state || {};
  const dispatch = useDispatch();
  const portfolios = useSelector((state) => state.portfolios.portfolios);

  // Fetch portfolios on mount
  useEffect(() => {
    dispatch(fetchPortfolios());
  }, [dispatch]);

  // Filter portfolios that belong to the same company as the selected portfolio
  const companyPortfolios = portfolios.filter(p => p.companyId === portfolio.companyId);

  const getDollarSigns = (price) => {
    if (price === '100-120') return '$';
    if (price === '120-250') return '$$';
    if (price === '250-above') return '$$$';
    return '';
  };

  return (
    <Box sx={{ py: 6, backgroundColor: "#f3f4f6" }}>
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          {/* Profile Section */}
          <Grid item xs={12} md={4}>
            <Paper
              elevation={4}
              sx={{
                padding: 4,
                borderRadius: 3,
                textAlign: 'center',
                backgroundImage: 'linear-gradient(to right, #6a11cb, #2575fc)',
                color: '#fff',
              }}
            >
              <Avatar
                src={`${ImageBaseUrl}/${portfolio.company.image}`}
                alt={portfolio.company.name}
                sx={{
                  width: 130,
                  height: 130,
                  margin: '0 auto 16px',
                  boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.3)',
                }}
              />
              <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
                {portfolio.company.name}
              </Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>
                {portfolio.company.email}
              </Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: 1, mb: 2 }}>
                {portfolio.company.userCategories.map((category) => (
                  <Chip
                    key={category.category.id}
                    label={category.category.name}
                    sx={{
                      backgroundColor: '#ffffff33',
                      color: '#fff',
                      fontWeight: 'bold',
                    }}
                  />
                ))}
              </Box>
              <Typography variant="body2" sx={{ mb: 1 }}>
                {portfolio.portfolioCities.map((city) => city.city.name).join(', ')}
              </Typography>
              <Typography variant="body2">
                {portfolio.portfolioBuildingTypes.map((type) => type.buildingType.name).join(', ')}
              </Typography>
            </Paper>
          </Grid>

          {/* Portfolio Section */}
          <Grid item xs={12} md={8}>
            <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
              Projects
            </Typography>
            <Grid container spacing={3}>
              {companyPortfolios.map((item, index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <Card
                    elevation={2}
                    sx={{
                      borderRadius: 3,
                      transition: 'transform 0.3s ease-in-out, box-shadow 0.3s',
                      '&:hover': {
                        transform: 'translateY(-5px)',
                        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.2)',
                      },
                    }}
                  >
                    <CardActionArea onClick={() => navigate('/portfolioDetail', { state: { portfolio: item } })}>
                      <CardMedia
                        component="img"
                        height="180"
                        image={item.portfolioImages.length > 0 ? `${ImageBaseUrl}/${item.portfolioImages[0].image}` : 'https://via.placeholder.com/300'}
                        alt={item.title}
                        sx={{ borderRadius: '12px 12px 0 0' }}
                      />
                      <CardContent sx={{ padding: 3 }}>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
                          {item.title}
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#666', fontStyle: 'italic', mb: 1 }}>
                          {getDollarSigns(item.price)}
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#555', textAlign: 'justify' }}>
                          {item.content?.length > 100 ? `${item.content.substring(0, 100)}...` : item.content}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ProfileDetailScreen;