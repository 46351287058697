import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../Network/NetworkManager';

export const fetchBuilding = createAsyncThunk(
    'building/fetchBuilding',
    async (_, { rejectWithValue }) => {
        try {
            const response = await api.get('/building-types'); // Call the /categories endpoint
            // console.log(response.data.data);
            return response?.data?.data; // Return the categories data
        } catch (error) {
            // Return a rejected value if there's an error
            return rejectWithValue(error.response.data);
        }
    }
);


const buildingSlice = createSlice({
    name: 'building',
    initialState: {
        building: [],
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchBuilding.pending, (state) => {
                state.loading = true; // Set loading to true while the API call is in progress
                state.error = null;
            })
            .addCase(fetchBuilding.fulfilled, (state, action) => {
                state.loading = false; // Set loading to false when the API call is successful
                state.building = action.payload; // Save the categories to the state
            })
            .addCase(fetchBuilding.rejected, (state, action) => {
                state.loading = false; // Set loading to false if the API call fails
                state.error = action.payload; // Save the error message to the state
            });
    },
});

export default buildingSlice.reducer    