import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  CssBaseline,
  Drawer,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SettingsIcon from "@mui/icons-material/Settings";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useNavigate } from "react-router-dom";

const Dashboard = ({ children }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const drawerWidth = 240;
  const navigate = useNavigate();

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      {/* Sidebar */}
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            backgroundColor: "#1976d2",
            color: "#fff",
          },
        }}
        variant="persistent"
        open={isDrawerOpen}
        onClose={toggleDrawer}
        ModalProps={{
          keepMounted: true,
        }}
        PaperProps={{
          elevation: 0,
        }}
      >
        <Toolbar>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ fontWeight: "bold", textAlign: "center", width: "100%" }}
          >
            Dashboard
          </Typography>
        </Toolbar>
        <List style={{ cursor: 'pointer' }}>
          <ListItem button onClick={() => navigate("/overview")}>
            <ListItemIcon>
              <DashboardIcon sx={{ color: "#fff" }} />
            </ListItemIcon>
            <ListItemText primary="Overview" />
          </ListItem>
          <ListItem button onClick={() => navigate("/add-portfolio")}>
            <ListItemIcon>
              <AddCircleIcon sx={{ color: "#fff" }} />
            </ListItemIcon>
            <ListItemText primary="Add Portfolio" />
          </ListItem>
          <ListItem button onClick={() => navigate("/settings")}>
            <ListItemIcon>
              <SettingsIcon sx={{ color: "#fff" }} />
            </ListItemIcon>
            <ListItemText primary="Update Profile" />
          </ListItem>
          <ListItem button onClick={() => navigate("/")}>
            <ListItemIcon>
              <ExitToAppIcon sx={{ color: "#fff" }} />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
      >
        <AppBar
          position="fixed"
          sx={{
            width: `calc(100% - ${isDrawerOpen ? drawerWidth : 0}px)`, // Dynamically adjust width based on sidebar state
            ml: `${isDrawerOpen ? drawerWidth : 0}px`, // Dynamically adjust margin based on sidebar state
            backgroundColor: "#3a6073",
          }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={toggleDrawer}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              Dashboard
            </Typography>
          </Toolbar>
        </AppBar>

        {/* Page content */}
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
};

export default Dashboard;