import React from 'react';
import { Box, Container, Grid, Link, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function Footer() {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: '#01AEFE',
        color: '#fff',
        py: 4,
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          {/* Column 1 */}
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              <Link href="/" color="inherit">
                ApniChhat
              </Link>
            </Typography>
            <Link href="/about" color="inherit" sx={{ display: 'block', mb: 1 }}>
              About
            </Link>
            {/* <Link href="#" color="inherit" sx={{ display: 'block', mb: 1 }}>
              How it works
            </Link>
            <Link href="#" color="inherit" sx={{ display: 'block', mb: 1 }}>
              Magazine
            </Link> */}
          </Grid>

          {/* Column 2 */}
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              Services
            </Typography>
            {/* onClick={() => navigate("/contractors", { state: { service: service.id } })} */}
            <Link
              onClick={() => navigate("/contractors", { state: { service: 1 } })}
              color="inherit" sx={{ display: 'block', mb: 1 }}>
              Architecture
            </Link>
            <Link
              onClick={() => navigate("/contractors", { state: { service: 2 } })}
              color="inherit" sx={{ display: 'block', mb: 1 }}>
              Contractors
            </Link>
            <Link

              onClick={() => navigate("/contractors", { state: { service: 3 } })}
              color="inherit" sx={{ display: 'block', mb: 1 }}>
              Interior Design
            </Link>

          </Grid>

          {/* Column 3 */}
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              Legal
            </Typography>
            <Link href="privacy-policy" color="inherit" sx={{ display: 'block', mb: 1 }}>
              Privacy Policy
            </Link>
            <Link href="terms-and-conditions" color="inherit" sx={{ display: 'block', mb: 1 }}>
              Terms and Conditions
            </Link>
            {/* <Link href="#" color="inherit" sx={{ display: 'block', mb: 1 }}>
              Cookie Policy
            </Link> */}
          </Grid>
        </Grid>

        <Box mt={4} textAlign="center">
          <Typography variant="body2" color="inherit">
            © {new Date().getFullYear()} ApniChhat. All rights reserved.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}

export default Footer;
