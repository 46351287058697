import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../Network/NetworkManager';

// Async Thunk to Fetch Categories
export const fetchCategories = createAsyncThunk(
  'categories/fetchCategories',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('/categories'); // Call the /categories endpoint
      // console.log(response.data.data);
      return response?.data?.data; // Return the categories data
    } catch (error) {
      // Return a rejected value if there's an error
      return rejectWithValue(error.response.data);
    }
  }
);

// Categories slice
const categoriesSlice = createSlice({
  name: 'categories',
  initialState: {
    categories: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategories.pending, (state) => {
        state.loading = true; // Set loading to true while the API call is in progress
        state.error = null;
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.loading = false; // Set loading to false when the API call is successful
        state.categories = action.payload; // Save the categories to the state
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.loading = false; // Set loading to false if the API call fails
        state.error = action.payload; // Save the error message to the state
      });
  },
});

// Export the reducer to include in the store
export default categoriesSlice.reducer;